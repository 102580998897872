/* ::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #000;
  border-radius: 8px;
}

::-webkit-scrollbar-track {
  background-color: #ccc;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--hover-color);
} */

.h-screen{
  height: 100dvh;
}

a {
  text-decoration: none !important;
  color: #0465b4;
}

a:hover {
  color: #1a489e;
}

.fs-5 {
  font-size: 20px !important;
}

.fs-6 {
  font-size: 16px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.btn-dismiss {
  background-color: #f9fafb !important;
  border-color: #f5f3f0 !important;
  color: #000000 !important;
}

.btn-dismiss:hover {
  background-color: #f5f3f0 !important;
  border-color: #f5f3f0 !important;
}

.text-justify {
  text-align: justify;
}

a.text-dark:hover {
  color: #2695d5 !important;
}

.bg-aliceblue {
  background-color: aliceblue;
}

.bg-none {
  background: none;
}

.button-info-mark {
  width: 16px;
  height: 16px;
  font-size: 10px !important;
  color: #a3a3a3;
  background-color: #ffffff;
  margin-left: 3px;
}

.two-line-text{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}

.content-math p{
  margin-bottom: 0 !important;
}

.faq-media{
  height: 300px;
}

.card-teacher-img{
  height: 260px;
}

/* responsive */

@media screen and (max-width: 1500px) {}

@media screen and (max-width: 1200px) {}

@media screen and (max-width: 992px) {}

@media screen and (max-width: 768px) {
  .card-teacher-img{
    height: 180px;
  }
}

@media screen and (max-width: 465px) {

  .fs-5 {
    font-size: 14px !important;
  }

  .fs-6 {
    font-size: 14px !important;
  }

}

.bg-gradient-primary{
  background-image: linear-gradient(to left, #2695d5, #00a2d8, #00aed7, #00b9d3, #02c3cd);
}


.btn-gradient-primary {
  background-image: linear-gradient(to left, #2695d5, #00a2d8, #00aed7, #00b9d3, #02c3cd);
  margin: 10px;
   padding: 15px 45px;
   text-align: center;
   text-transform: uppercase;
   transition: 0.5s;
   background-size: 200% auto;
   color: white !important;            
   box-shadow: 0 0 20px #eee;
   border-radius: 10px;
   display: block;
 }

 .btn-gradient-primary:hover {
   background-position: right center; 
   color: #fff;
   text-decoration: none;
 }

.text-main {
  color:#2695d5 ;
}