* {
    margin: 0;
    font-family: 'Quicksand', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  

  .birthday-section h1{
    font-family: "Pacifico", cursive;

  }

  .birthday-modal h1{
    font-family: "Pacifico", cursive;
  }